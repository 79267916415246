<template>
  <div class="page-wrapper text-right">
    <a-locale-provider :locale="zhCN">
    <a-pagination
      class="page-content"
      :size="size"
      :pageSizeOptions="['10', '20', '30', '40', '50']"
      showQuickJumper
      showSizeChanger
      :showTotal="showTotal"
      :total="total"
      :pageSize="pageSize"
      :current="current"
      @change="onPageChange"
      @showSizeChange="onPageSizeChange"
    >
    </a-pagination>
    </a-locale-provider>
  </div>
</template>

<script>
import zhCN from 'ant-design-vue/es/locale-provider/zh_CN';
export default {
  data () {
    return {
        showTotal: (total, range) => `显示 ${range[0]} ~ ${range[1]} 条记录，共 ${total} 条记录`,
         zhCN
    }
  },
  props: {
    total: {
      require: true,
      type: Number,
      default:0
    },
    pageSize: {
      require: true,
      type: Number,
      default:10
    },
    current: {
      require: true,
      type: Number,
      default:1
    },
    size:{
        type:String,
        default:'default'
    },
  },
  methods: {
    onPageChange (current, pageSize) {
      this.$emit('change', current, pageSize)
    },
    onPageSizeChange (current, pageSize) {
      this.$emit('showSizeChange', current, pageSize)
    }
  }
}
</script>

<style lang='less' scoped>

</style>
